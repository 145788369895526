<template>
  <div class="addShoppingCart" v-loading="prodDet.loading">
    <div class="flex-row">
        <div style="flex:0 0 400px">
          <el-image style="width: 400px; height: 400px;" :src="prodDet.data.img_info?prodDet.data.img_info.title[0]:''" fit="contain"></el-image>
        </div>
        <div class="flex-auto margin-left-20">
          <div class="font-size-20 bold">{{prodDet.data.spmch}}</div>
          <div class="detail-price margin-top-10">
            <div class="flex-row align-items-center">
              <div>超值低价</div>
              <el-tag size="mini" type="warning" effect="dark" class="margin-left-10">{{activeSpec.sale_info}}</el-tag>
              <el-tag size="mini" type="warning" effect="dark" class="margin-left-10">库存：{{activeSpec.pcjcshl}}</el-tag>
            </div>
            <div class="flex-row align-items-center margin-top-10">
              <div>￥<span class="color-orange font-size-24 bold">{{activeSpec.hshsj}}</span></div>
              <div style="text-decoration: line-through;" class="margin-left-10">{{activeSpec.ori_price}}</div>
            </div>
          </div>
          <div class="flex-row justify-between line-height-medium margin-tb-20 border-bottom padding-bottom-20">
            <div>
              <div v-for="(item,index) in baseInfo(prodDet.data.base_info_left)" :key="index">
                <span class="color-sub">{{item.key}}：</span>{{item.value}}
              </div>
            </div>
            <div>
              <div v-for="(item,index) in baseInfo(prodDet.data.base_info_right)" :key="index">
                <span class="color-sub">{{item.key}}：</span>{{item.value}}
              </div>
            </div>
          </div>
          <div class="flex-row align-items-center">
              <span class="color-sub text-nowrap">批号：</span>
              <div class="specifications">
                <div v-for="(item,index) in prodDet.data.price_info" :key="index" @click="changeSpec(index)" :class="{'active':activeSpecIndex==index}">{{item.pihao}}</div>
              </div>
            </div>
          <div class="flex-row text-nowrap margin-top-20">
            <div class="flex-row align-items-center margin-right-20" v-if="activeSpec.low_price!=activeSpec.high_price">
              修改价格(￥{{activeSpec.low_price}}-{{activeSpec.high_price}})：￥
              <el-input v-model="customPrice" @blur="checkCustomPrice" style="width: 80px; margin: 0 3px;"></el-input> 
            </div>
            <div class="flex-row align-items-center">
              数量：
              <el-button @click="changeNum('reduce')">-</el-button> 
              <el-input class="text-align-center" v-model="prodNum" @blur="checkProdNum" style="width: 70px; margin: 0 3px;"></el-input> 
              <el-button @click="changeNum('add')">+</el-button> 
            </div>
          </div>
          <div class="flex-row margin-top-20">
            <el-button type="success" round icon="el-icon-shopping-cart-2" :disabled="!activeSpec.pcjcshl||prodNum<=0" :loading="cartLoading" @click="addCart">加入购物车</el-button>
            
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'addShoppingCart',
  components: {},
  props:{
    q:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      prodDet:{//商品详情
        loading:false,
        data:{}
      },
      activeSpecIndex:1,//选中的规格批号索引
      prodNum:1,//购买产品选择的数量
      customPrice:0,//修改的价格

      collectLoading:false,//收藏loading
      cartLoading:false,//加入购物车loading
    }
  },
  computed:{
    baseInfo(){
      return (res)=>{
        if(res){
          let arr=res.split('||')
          return arr.map(val=>{
            let item=val.split('：')
            return {key:item[0],value:item[1]}
          })
        }else{
          return []
        }
      }
    },
    //选中的规格批号
    activeSpec(){
      let val=this.prodDet.data.price_info
      if(val){
        return val[this.activeSpecIndex]
      }else{
        return {}
      }
    },
    //购买数量step
    numStep(){
      let val=this.activeSpec
      if(val.pihao){
        return val.clbz||1
      }else{
        return 1
      }
    }
  },
  methods:{
    getData(){
      if(this.q.spid){
        this.getProdDetail()
      }
    },
    //获取商品详情
    getProdDetail(){
      this.prodDet.loading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        hd_id:this.q.hd_id||''
      }
      this.$instance.get('/product/get_product_info',{params})
      .then(res=>{
        this.prodDet.loading=false
        if(res.data.code==0){
          this.prodDet.data=res.data.data
          this.activeSpecIndex=0
          this.customPrice=this.activeSpec.hshsj
          this.checkProdNum()
        }else{
          this.prodDet.data={}
        }
      })
      .catch(()=> {
        this.prodDet.loading=false
        this.prodDet.data={}
      }); 
    },
    //设置购买数量
    changeNum(type){
      if(type=='reduce'){
        this.prodNum-=this.numStep
      }else if(type=='add'){
        this.prodNum+=this.numStep
      }
      this.checkProdNum()
    },
    checkProdNum(){
      if(isNaN(this.prodNum)){
        this.prodNum=this.numStep
      }
      else if(this.prodNum>=this.activeSpec.pcjcshl||(this.activeSpec.pcjcshl==0&&this.prodNum<this.activeSpec.pcjcshl)){
        this.prodNum=this.activeSpec.pcjcshl
      }
      else if(this.prodNum<=this.numStep){
        this.prodNum=this.numStep
      }
      else if(this.prodNum%this.numStep!=0){
        this.prodNum=this.prodNum-(this.prodNum%this.numStep)
      }
    },
    changeSpec(index){
      this.activeSpecIndex=index;
      this.customPrice=this.activeSpec.hshsj
      this.checkProdNum()
    },
    checkCustomPrice(){
      if(isNaN(this.customPrice)){
        this.customPrice=this.activeSpec.hshsj
      }
      else if(this.customPrice>=this.activeSpec.high_price){
        this.customPrice=this.activeSpec.high_price
      }
      else if(this.customPrice<=this.activeSpec.low_price){
        this.customPrice=this.activeSpec.low_price
      }
    },
    //加入购物车
    addCart(){
      if(!this.activeSpec.can_sale){
        this.$alert(`${this.activeSpec.memo}（业务咨询电话：${this.activeSpec.sale_phone}）`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {})
        return false
      }
      this.cartLoading=true
      let params= {
        ...this.$store.state.basicParams,
        spid:this.q.spid,
        hd_id:this.q.hd_id||'',
        pihao: this.activeSpec.pihao,
        sp_buy_sl: this.prodNum,
        belonger_user_id:0,
        sp_buy_jiage:this.activeSpec.low_price!=this.activeSpec.high_price?this.customPrice:0
      }
      this.$instance.post('/cart/add_product',this.$qs.stringify(params))
      .then(res=>{
        this.cartLoading=false
        if(res.data.code==0){
          this.$message({message: '商品成功加入购物车',type:'success',center:true,})
        }
      })
      .catch(()=> {
        this.cartLoading=false
      }); 
    },
   
  },
  created(){
    this.getData()
  },
  mounted(){

  },
  watch: {
    'q'(){
        this.prodDet.data={}
        this.prodNum=1
        this.getData()
    }, 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .specifications{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  div{
    padding: 5px 10px;
    border:#d3d3d3 solid 2px;
    margin: 5px;
    position: relative;
    cursor: pointer;
  }
  div.active{
    border: #fe5e12 solid 2px;
  }
  div.active::before{
    content: '';
    position: absolute;
    border-right: #fe5e12 solid 8px;
    border-bottom: #fe5e12 solid 8px;
    border-left:transparent solid 8px;
    border-top: transparent solid 8px;
    height: 0;
    width: 0;
    bottom:0;
    right: 0;
    color: #fff;
  }
  div.active::after{
    content: '✔';
    font-size: 10px;
    position: absolute;
    bottom:0;
    right: 0;
    line-height: 1em;
    color: #fff;
  }
}
</style>
