<template>
  <div class="classification">
    <div v-for="(item,index) in defaultList" :key="index">
      <el-popover placement="right" width="400" trigger="hover" v-if="item.sub_list.length">
        <div style="max-height: 500px; overflow-y: auto;" @mouseenter="childMouseenter" @mouseleave="childMouseleave">
            <div class="flex-row flex-wrap">
              <div class="link margin-lr-10 margin-tb-5" v-for="(item2,index2) in item.sub_list" :key="index2"
              @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({prodClass:[item.big_class_name,item2]})},relace:true})">{{item2}}</div>
            </div>
        </div>
        <div slot="reference">
          <div class="link flex-row align-items-center justify-between" @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({prodClass:[item.big_class_name]})},relace:true})"
           style="padding:9px 10px"><span>{{item.big_class_name}}
          </span><i class="el-icon-arrow-right font-size-12"></i>
        </div>
        </div>
      </el-popover>
      <div v-else class="link flex-row align-items-center justify-between" @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({prodClass:[item.big_class_name]})},relace:true})"
       style="padding:9px 10px"><span>{{item.big_class_name}}</span><i class="el-icon-arrow-right font-size-12"></i>
      </div>
    </div>
    
    <el-popover placement="right" width="400" trigger="hover" v-if="moreList.length">
      <div style="max-height: 500px; overflow-y: auto;">
        <div class="margin-tb-10 padding-bottom-10 border-bottom" v-for="(item,index) in moreList" :key="index">
          <div class="link bold margin-left-10" @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({prodClass:[item.big_class_name]})},relace:true})">{{item.big_class_name}}</div>
          <div class="flex-row flex-wrap margin-top-10" v-if="item.sub_list.length">
            <div class="link margin-lr-10 margin-bottom-5" v-for="(item2,index2) in item.sub_list" :key="index2"
            @click="$root.jump({path:'/mall/prodList',query:{q:crypto.encrypt({prodClass:[item.big_class_name,item2]}),relace:true}})">{{item2}}</div>
          </div>
        </div>
      </div>
      <div slot="reference">
        <div class="more"><span>更多</span> <i class="el-icon-caret-right"></i></div>
      </div>
    </el-popover>

  </div>
</template>

<script>

import crypto from "@/common/crypto";
export default {
  name: 'ProductClass',
  components: {},
  props:{
    defaultShowNum:{
      type:Number,
      default:0,
    }
  },
  data() {
    return {
      crypto,
      list:[]
    }
  },
  computed:{
    defaultList(){
      if(this.list.length>this.defaultShowNum&&this.defaultShowNum){
        return this.list.slice(0,this.defaultShowNum)
      }else{
        return this.list
      }
    },
    moreList(){
      if(this.list.length>this.defaultShowNum&&this.defaultShowNum){
        return this.list.slice(this.defaultShowNum)
      }else{
        return []
      }
    }
  },
  methods:{
    childMouseenter(){
      this.$emit('childMouseenter')
    },
    childMouseleave(){
      this.$emit('childMouseleave')
    },
    //获取分类列表
    getList(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/product/get_product_class_list',{params})
      .then(res=>{
        if(res.data.code==0){
          this.list=res.data.data
        }
      })
      .catch(()=> {}); 
    },
  },
  created(){
    this.getList()
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  
  .classification{
    width: 200px;
    font-size: 16px;
    position: relative;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    
    .more{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        font-size: 10px;
        text-align: center;
        padding: 10px;
        color: #fe5e12;
        cursor: pointer;
      }
  }
</style>
